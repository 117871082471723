/* jQuery*/
$(document).ready(function() {

	/* Smooth Scrolling (cf. https://css-tricks.com/snippets/jquery/smooth-scrolling/) */
	// Select all links with hashes
	$('a[href*="#"]')
	  // Remove links that don't actually link to anything
	  .not('[href="#"]')
	  .not('[href="#0"]')
	  .click(function(event) {
	    // On-page links
	    if (
	      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
	      && 
	      location.hostname == this.hostname
	    ) {
	      // Figure out element to scroll to
	      var target = $(this.hash);
	      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
	      // Does a scroll target exist?
	      if (target.length) {
	        // Only prevent default if animation is actually gonna happen
	        event.preventDefault();
	        $('html, body').animate({
	          scrollTop: target.offset().top
	        }, 1000, function() {
	          // Callback after animation
	          // Must change focus!
	          var $target = $(target);
	          $target.focus();
	          if ($target.is(":focus")) { // Checking if the target was focused
	            return false;
	          } else {
	            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
	            $target.focus(); // Set focus again
	          };
	        });
	      }
	    }
	  });


	/* Mobile Menu Toggle */
	$(".mobile-menu-toggle").click(function(){
		$("#mobile-menu").toggle();
	    return false;
	});
	$("#mobile-menu a").click(function(){
	    $("#mobile-menu").hide();
	});
	$(document).keyup(function(e){
	    // ESC-Key
	    if(e.keyCode == 27){
	        $("#mobile-menu").hide();
	    }
	});

	/* Ankauf Genre List Animation */

	$("#ankauf-genres").hover(function(){
		$(this).children("li").each(function(){
			$(this).addClass('text-gray-500');
		});
	});

	$("#ankauf-genres").mouseleave(function(){
		$(this).children("li").each(function(){
			$(this).removeClass('text-gray-500');
		});
	});

	$("#ankauf-genres li").mouseover(function(){
		$(this).removeClass('text-gray-500');
	});

	$("#ankauf-genres li").mouseleave(function(){
		$(this).addClass('text-gray-500');
	});

	/* wertermittlung faktoren animation */
	$("#wertermittlung-info-faktoren").mouseover(function(){
		//$(".wertermittlung-faktoren").addClass('bg-orange-200');
		$(".wertermittlung-faktoren").addClass('border-b border-dotted border-black');
	});

	$("#wertermittlung-info-faktoren").mouseleave(function(){
		//$(".wertermittlung-faktoren").removeClass('bg-orange-200');
		$(".wertermittlung-faktoren").removeClass('border-b border-dotted border-black');
	});

	/* Sammlungsgröße Animation */
	$("#abholung-info-sammlungsgroesse").mouseover(function(){
		//$(".abholung-sammlungsgroesse").addClass('bg-orange-200');
		$(".abholung-sammlungsgroesse").addClass('border-b border-dotted border-black');
	});

	$("#abholung-info-sammlungsgroesse").mouseleave(function(){
		//$(".abholung-sammlungsgroesse").removeClass('bg-orange-200');
		$(".abholung-sammlungsgroesse").removeClass('border-b border-dotted border-black');
	});	

});